export const CONFIG = {
  COMPANY_ID: '44954551-2EDD-4A77-B17C-DF956EC49BA4',
  API_URL:
    process.env.REACT_APP_NODE_ENV !== 'production'
      ? 'https://onlypay-campanhas-hotsite-api-hml.azurewebsites.net/api'
      : 'https://onlypay-campanhas-hotsite-api-wa.azurewebsites.net/api',
  INDICATION_API_URL:
    process.env.REACT_APP_NODE_ENV !== 'production'
      ? 'https://onlypay-mgm-hml.azurewebsites.net/api'
      : 'https://onlypay-mgm-hml.azurewebsites.net/api',
  TENANT_ID: '9D0985F8-B4DF-4D6F-9228-54A3038A0193',
  CATALOG_WSDL:
    'https://onlypay.com.br:3032/https://catalogo.pontosonly.com.br/pontosonlysinglesignon.asmx?WSDL',
  CATALOG_LOGIN_URL: 'https://catalogo.pontosonly.com.br/Login/SSO',

  THEME: 'default',

  MODULES: {
    LOGIN: {
      DISABLED: false,

      // CADASTRO POR CNPJ DA COMPANY
      signupByCompany: true,
      /*
      genderList: [
        { id: '7CE0B50F-5C87-4DA9-A58F-C7B7AEB247ED', title: 'Masculino' },
        { id: '7D183B42-2A3A-4263-B962-D9B754C5D9BC', title: 'Feminino' }
      ]
      */
    },
    HEADER: {
      // HEIGHT: '10vh',
      FLOATING_ON_SCROLL: { DISABLED: true },
      SHOW_POINTS: { DISABLED: false }
    },
    HOMESLIDER: {
      DISABLED: false,
      BANNER_TYPE_NAME: 'Home - Full'
    },
    HOMEGRID: {
      DISABLED: false,
      BANNERS: {
        LEFT: { BANNER_TYPE_NAME: 'Vertical Esquerda' },
        RIGHT: { BANNER_TYPE_NAME: 'Mini Banner' },
      },
    },
    CATALOG: { DISABLED: false },
    FOOTER: {
      COLUMNS: { LEFT: { HIDDEN: false }, RIGHT: { HIDDEN: false } },
      FORM_CONTACT_ASSOCIATE: { DISABLED: false },
      COMPANY_CONTACT_INFOS: { DISABLED: false },
    },
    LGPD: {
      COOKIE_BOX: { DISABLED: true },
      CONSENT_BOX: { DISABLED: true },
    },
  },
}
