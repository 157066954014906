import React, { useContext, useEffect } from 'react'
import { MainContext } from '@onlypay/default-main-provider'

import { _MainQuiz } from './styles'

const Quiz = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Responda o Quiz'), [])

  return (
    <_MainQuiz>
      <h4>Em breve!</h4>
      <p>Fique atento! Logo você receberá um alerta com maiores informações sobre o lançamento do nosso <span>Quiz</span>. 😎</p>
    </_MainQuiz>
  )
}

export default Quiz
