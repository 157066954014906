import React, { useContext, useEffect } from 'react'

import { pageProtected } from '@onlypay/core-default'
import { MainContext } from '@onlypay/default-main-provider'
import { UserProfileIncomplete } from '@onlypay/default-mod-login'

import { _MainUserProfileIncomplete } from './styles'

const UserProfileIncompletePage = props => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle(null), [])

  return pageProtected(
    <_MainUserProfileIncomplete>
      <UserProfileIncomplete {...props} />
    </_MainUserProfileIncomplete>,
    false,
  )
}

export default UserProfileIncompletePage
