import styled from 'styled-components'
import { device } from '@onlypay/core-default'

export const _MainLogin = styled.div`
  display: flex;
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 100px 0 130px;
  }

  @media ${device.iPadMini} {
    padding: 80px 0 40px;
  }

  @media ${device.iPadMini} and ( orientation: portrait ) {
    padding: 100px 0 40px;
  }
`
