import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'
import { pageProtected } from '@onlypay/core-default'

import { RegulationText } from '@onlypay/default-mod-regulations'

import { _MainRegulations } from './styles'

const RegulationsPage = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Regulamento'), [])

  return <_MainRegulations>{pageProtected(<RegulationText />)}</_MainRegulations>
}

export default RegulationsPage
