import HomePage from './pages/Home'
// import NewsPage from './pages/News'
import DownloadCenterPage from './pages/DownloadCenter'
import CampaignsPage from './pages/Campaigns'
import CatalogPage from './pages/Catalog'
import PointsPage from './pages/Points'
import PointsListPage from './pages/Points/List'
import PointsExtractListPage from './pages/Points/Extract'
import PointsExtractComissionPage from './pages/Points/ExtractComission'
import RegulationsPage from './pages/Regulations'
import ProfilePage from './pages/Profile'
import QuizPage from './pages/Quiz'
import ContactPage from './pages/Contact'
import AboutPage from './pages/About'

import LoginPage from './pages/Login'
import UserProfileIncompletePage from './pages/Login/UserProfileIncomplete'
import ResetPasswordPage from './pages/Login/ResetPassword'
import { NotFound as NotFoundPage } from '@onlypay/theme-default'

const routes = [
  {
    path: '/',
    title: 'Home',
    icon: '/icons.svg#olymp-home-icon',
    component: HomePage,
    exact: true,
    onlyLogged: false,
    hidden: false,
    showToMobile: true,
  },
  {
    path: '/login',
    title: 'Login',
    component: LoginPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/login/completar-cadastro',
    title: 'Completar meus cadastro',
    component: UserProfileIncompletePage,
    onlyLogged: true,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/login/esqueci-minha-senha',
    title: 'Esqueci minha senha',
    component: ResetPasswordPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/sobre-nos',
    title: 'Sobre nós',
    component: AboutPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/meus-dados',
    title: 'Meus Dados',
    component: ProfilePage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/quiz',
    title: 'Quiz',
    component: QuizPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/campanhas',
    title: 'Campanhas',
    icon: '/icons.svg#olymp-badge-icon',
    component: CampaignsPage,
    onlyLogged: false,
    showToMobile: true,
  },
  {
    path: '/catalogo-de-premios',
    title: 'Catálogo',
    icon: '/icons.svg#olymp-shopping-bag-icon',
    component: CatalogPage,
    onlyLogged: false,
    hidden: false,
    showToMobile: true,
  },
  /*{
		path: '/premios',
		title: 'Prêmios',
		icon: '/icons.svg#olymp-trophy-icon',
		component: PointsListPage,
    onlyLogged: true,
		hidden: true,
    showToMobile: true,
  },*/
  {
    path: '/regulamento',
    title: 'Regulamento',
    icon: '/icons.svg#olymp-checked-calendar-icon',
    component: RegulationsPage,
    onlyLogged: false,
    hidden: false,
    showToMobile: true,
  },
  {
    path: '/novidades',
    title: 'Novidades',
    icon: '/icons.svg#olymp-checked-calendar-icon',
    component: DownloadCenterPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  /*{
		path: '/tabela-de-pontos',
		title: 'Tabela de Pontos',
		icon: '/icons.svg#olymp-status-icon',
		component: PointsListPage,
    onlyLogged: true,
  },*/
  {
    path: '/meus-pontos/inserir-pontos',
    title: 'Insira seus pontos',
    icon: '/icons.svg#olymp-thunder-icon',
    component: PointsPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/meus-pontos/extrato-da-equipe',
    title: 'Extrato de Pontos - Equipe',
    icon: '/icons.svg#olymp-stats-icon',
    component: PointsExtractComissionPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: true,
  },
  {
    path: '/meus-pontos/extrato-de-pontos',
    title: 'Extrato de Pontos',
    icon: '/icons.svg#olymp-stats-icon',
    component: PointsExtractListPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: false,
  },
  {
    path: '/meus-pontos/utilizacao-de-pontos',
    title: 'Extrato de Utilizacao de Pontos',
    icon: '/icons.svg#olymp-stats-icon',
    component: PointsListPage,
    onlyLogged: true,
    hidden: true,
    showToMobile: false,
  },
  /*{
		path: '/faq',
		title: 'FAQ',
		icon: '/icons.svg#olymp-speech-balloon-icon',
		component: ContactPage,
    onlyLogged: true,
		hidden: true,
    showToMobile: false,
  },*/
  {
    path: '/fale-conosco',
    title: 'Fale conosco',
    icon: '/icons.svg#olymp-chat---messages-icon',
    component: ContactPage,
    onlyLogged: false,
    showToMobile: true,
    props: {
      custom: 'contact',
    },
  },
  {
    path: '/sair',
    title: 'Sair',
    icon: '/icons.svg#olymp-logout-icon',
    component: LoginPage,
    onlyLogged: true,
    hidden: true,
  },
  {
    path: '*',
    title: 'NotFound',
    component: NotFoundPage,
    onlyLogged: false,
    hidden: true,
    showToMobile: false,
  },
]

export default routes
