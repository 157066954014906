import styled from 'styled-components'
import { colors, device } from '@onlypay/core-default'

export const _MainUserProfileIncomplete = styled.div`
	display: flex;
	width: 100%;

	@media ${device.tablet} {
    width: 100%;
	}

  @media ${device.iPadMini} {
    width: 100%;
  }

  @media ${device.iPadMini} and (orientation: portrait) {
    width: 100%;
  }
`
