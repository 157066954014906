import React, { useContext, useEffect } from 'react'

import { pageProtected } from '@onlypay/core-default'
import { MainContext } from '@onlypay/default-main-provider'
import Login from '@onlypay/default-mod-login'

import { _MainLogin } from './styles'

const LoginPage = props => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle(null), [])

  return pageProtected(
    <_MainLogin>
      <Login {...props} />
    </_MainLogin>,
    false,
  )
}

export default LoginPage
