export const FAVICON_URL = '/images/favicon.ico'
export const LOGO_URL = '/images/logo_hoya.png'
export const LOGO_ICON_URL = '/images/logo_hoya.png'
export const LOGO_WHITE_URL = '/images/logoH_white.png'
export const LOGO_BLUE_URL = '/images/logo_hoya.png'
export const LOGO_SLOGAN_BLUE_H_URL = '/images/logo_hoya.png'

export const GA_UA = 'UA-165075972-1'
export const META = {
  NAME: 'Hoya',
  TITLE: 'Hoya - Hiper Prêmios',
  DESCRIPTION: 'Hoya - Infinitas chances de ganhar',
  COMPANY: 'Hoya - Infinitas chances de ganhar',
  SUBJECT: 'Hoya - Infinitas chances de ganhar',
  KEYWORDS: 'Hoya - Infinitas chances de ganhar',
  RATING: 'general',
  GOOGLEBOT: 'index, follow',
  GOOGLE_SITE_VERIFICATION: '',
  AUDIENCE: 'all',
  REPLY_TO: 'contato@onlypay.com.br',
  CANONICAL: 'https://www.hiperpremios.com.br/',
  CONTENT_LANGUAGE: 'pt-br',
  THEME_COLOR: '#0FC9C3',
  MOBILE_WEB_APP_CAPABLE: 'yes',
  APPLE_MOBILE_WEB_APP_CAPABLE: 'yes',
  MOBILE_WEB_APP_STATUS_BAR_STYLE: 'black-translucent',
  ROBOTS: 'all',
  LOGO_ICON: '/images/logoHP.png',
  OG_TITLE: 'Hoya - Infinitas chances de ganhar',
  OG_TYPE: 'company',
  OG_URL: 'https://www.hiperpremios.com.br/',
  OG_IMAGE: '/images/logo_hoya.png',
  OG_SITE_NAME: 'Hoya - Infinitas chances de ganhar',
  OG_DESCRIPTION: 'Hoya - Infinitas chances de ganhar',
}

export const M_QUERIES_VERSION = null

export const CAMPAIGN_NAME = 'Hiper Prêmios'

export const SOCIAL_NETWORK = {
  FACEBOOK: 'https://www.facebook.com/lenteshoya/',
  INSTAGRAM: 'https://www.instagram.com/instahoya/',
  YOUTUBE: 'https://www.youtube.com/channel/UC029i_LFgq53REP95CgeRXg',
  LINKEDIN: null,
  WHATSAPP: null,
}

export const avatarMenuList = [
  {
    label: 'Meus Dados',
    attrs: {
      to: '/meus-dados',
      title: 'Meus Dados',
    },
  },
  {
    label: 'Inserir Pontos',
    attrs: {
      to: '/meus-pontos/inserir-pontos',
      title: 'Inserir Pontos',
    },
  },
  {
    label: 'Extrato da equipe',
    attrs: {
      to: '/meus-pontos/extrato-da-equipe',
      title: 'Extrato da Equipe',
    },
    rules: ['8cef3656-6a82-427b-9569-ad57ec3290b5', '7fb6e02e-6695-4fd1-b565-33221925a07a']
  },
  {
    label: 'Extrato de Pontos',
    attrs: {
      to: '/meus-pontos/extrato-de-pontos',
      title: 'Extrato de Pontos',
    },
  },
  /*
  {
    label: 'Utilização de Pontos',
    attrs: {
      to: '/meus-pontos/utilizacao-de-pontos',
      title: 'Extrato de Utilização de Pontos',
    },
  },
  */
  {
    label: 'Novidades',
    attrs: {
      to: '/novidades',
      title: 'Novidades',
    },
  },
  {
    label: 'Quiz',
    attrs: {
      to: '/quiz',
      title: 'Conheça nosso Quiz',
    },
  },
]

export const sitemapList = [
  {
    path: '/sobre-nos',
    title: 'Sobre nós',
    listPosition: 0
  },
  {
    path: '/campanhas',
    title: 'Campanhas',
    listPosition: 0
  },
  {
    path: '/catalogo',
    title: 'Catálogo',
    listPosition: 0
  },
  {
    path: '/novidades',
    title: 'Novidades',
    listPosition: 1
  },
  {
    path: '/fale-conosco',
    title: 'Contato',
    listPosition: 1
  },
]

export const colors = {
  /* STATUS */
  ERROR: '#ED4B68',
  WARNING: '#FFC701',
  SUCCESS: '#4CAF50',
  SUCCESS_BOOTSTRAP: '#28a745',
  DISABLED: '#666666',

  /* GLOBAL */
  TRANSPARENT: 'transparent',
  BLACK: '#000000',
  BLUE: '#00ABCC',
  WHITE: '#FFFFFF',
  GRAY: '#CCCCCC',
  GRAY_LIGHT: '#EDF2F6',
  GRAY_DEFAULT: '#444444',
  GRAY_DARK: '#9A9FBF',
  GRAY_DARKER: '#9B9B9B',
  GREEN: '#BCDEA1',
  YELLOW: '#FFC701',
  MEDIA: '#FFC701',
  DESIGN: '#ED4B68',
  TECHNOLOGY: '#84C9F2',
  CONTENT: '#BCDEA1',
  PERFORMANCE: '#BCDEA1',
  OTHER: '#EEEEEE',

  /* HOYA - Layout Novo */
  BG_HEADER: '#F2F2F2',
  BG_FOOTER: '#141414',

  THEME: {},

  COMPONENTS: {},

  BG_LIGHT: '#F2F2F2',
  PRIMARY: '#13A1E9',
  SECONDARY: '#0FC9C3',
  LOADING: '#13A1E9',
  LINK_COLOR: '#191919',
  GRAY: {
    LVL0: '#F5F5F5',
    LVL1: '#DDDDDD',
    LVL2: '#BCBCBC',
    LVL3: '#838383',
    LVL4: '#141414',
  },
}

colors.THEME = {
  BG_HEADER: '#F2F2F2',
  BG_FOOTER: '#141414',
  BG_SCROLL_TO_TOP: '#13A1E9',
}

colors.COMPONENTS = {
  TABS: {
    TEXT_ACTIVE: '#495057',
  },
  FORM: {
    USER_LOGIN: { INPUT_MARGIN_BOTTOM: '0.5vh', INPUT_PWD_MARGIN_BOTTOM: '2.8vh' },
    USER_FIRST_LOGIN: { INPUT_MARGIN_BOTTOM: '3.8vh' },
    USER_INCOMPLETE: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    USER_PROFILE: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    USER_ADDRESS: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    UPDATE_PASSWORD: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    CONTACT: { INPUT_MARGIN_BOTTOM: '2.6vh' },
    INPUT_PADDING: '2.5vh 1.6vw',
    RADIO_PADDING: '2.5vh',
    TEXT_COLOR: '#495057',
    BORDER_COLOR: '#E6ECf5',
    LABEL_FONT_FAMILY: '"Nunito Sans", sans-serif',
    LABEL_FONT_SIZE: '14px',
    LABEL_COLOR: '#495057',
    LABEL_UPPERCASE: false,
    LABEL_ITALIC: false,
    PLACEHOLDER_FONT_FAMILY: '"Nunito Sans", sans-serif',
    PLACEHOLDER_FONT_SIZE: '18px',
    PLACEHOLDER_COLOR: '#495057',
    PLACEHOLDER_UPPERCASE: false,
    PLACEHOLDER_ITALIC: false,
    PLACEHOLDER_FONT_WEIGHT: '400',
    BUTTON_FONT_FAMILY: '"Montserrat", sans-serif',
    BUTTON_FONT_SIZE: '12px',
    BUTTON_LINE_HEIGHT: '16px',
    BUTTON_COLOR: '#0FC9C3',
    BUTTON_LABEL_COLOR: '#495057',
    BUTTON_UPPERCASE: true,
    BUTTON_ITALIC: false,
    BUTTON_FONT_WEIGHT: '500',
    BUTTON_BORDER_RADIUS: '.25rem',
  },
}
