import React, { useContext, useEffect } from 'react'

import { pageProtected } from '@onlypay/core-default'
import { MainContext } from '@onlypay/default-main-provider'

import EditProfile from '@onlypay/default-mod-profile'

import { _MainProfile } from './styles'

const Profile = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Meus Dados'), [])

  return <_MainProfile>{pageProtected(<EditProfile />)}</_MainProfile>
}

export default Profile
