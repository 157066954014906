import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'
import { pageProtected } from '@onlypay/core-default'

import { PointsExtract } from '@onlypay/default-mod-points'

const Extract = () => {
	const mainContext = useContext(MainContext)

	useEffect(() => mainContext.methods.changePageTitle('Extrato de Pontos'), [])

	return pageProtected(<PointsExtract />)
}

export default Extract
