import styled from 'styled-components'
import { colors, device } from '@onlypay/core-default'

export const _MainRegulations = styled.div`
  display: flex;

  @media ${device.tablet} {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${colors.GRAY.LVL1};
    background: ${colors.WHITE};
    padding: 30px;
  }

  @media ${device.iPadMini} {
    width: 100%;
  }

  @media ${device.iPadMini} and (orientation: portrait) {
    width: 100%;
  }
`
