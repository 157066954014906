import styled from 'styled-components'
import { LOGO_URL, LOGO_WHITE_URL, colors, device } from '@onlypay/core-default'

export const SCROLL = {
  BG: 'rgba(0, 171, 204, 1)',
  HOVER: 'rgba(0, 171, 204, 0.8)'
}

export const _Style = styled.div`
  .onlypay-header {
    a {
      &.logo {
        width: 120px;
        height: 50px;
        background: url('${LOGO_WHITE_URL}') no-repeat 50% 60% / auto 70%;
        flex-basis: auto;
      }
    }

    @media ${device.tablet} {
      a {
        &.logo {
          width: 130px;
          height: 100px;
          background: url('${LOGO_URL}') no-repeat 50% 50% / 100% auto;
        }
      }
    }
  }

  p {
    color: ${colors.GRAY.LVL3};
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.SECONDARY};
    border: 0;
    color: ${colors.WHITE};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    font-family: 'Gotham', sans-serif;
    font-size: 2vh;
    text-transform: uppercase;
    border-radius: 5vh;
    padding: 2vh 4vw;
    transition: 0.3s;

    &:hover, &:focus, &:active {
      opacity: 0.8;
      box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.6);
      text-decoration: none;
    }
  }
`
