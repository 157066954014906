import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'
import { pageProtected } from '@onlypay/core-default'

import { InsertPoints } from '@onlypay/default-mod-points'

import { _MainPoints } from './styles'

const Points = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Insira seus pontos'), [])

  return pageProtected(
    <_MainPoints>
      <InsertPoints />
    </_MainPoints>,
  )
}

export default Points
