import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'

import { FormContactWithFaq } from '@onlypay/default-mod-contact'

import { _MainContact } from './styles'

const Contact = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Fale Conosco'), [])

  return (
    <_MainContact>
      <FormContactWithFaq />
    </_MainContact>
  )
}

export default Contact
