import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'
import { pageProtected } from '@onlypay/core-default'

import { CatalogAuth } from '@onlypay/default-mod-catalog'

const Catalog = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Catálogo de Prêmios'), [])

  return pageProtected(<CatalogAuth />)
}

export default Catalog
