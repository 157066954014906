import React, { useContext, useEffect } from 'react'
import { MainContext } from '@onlypay/default-main-provider'

import { _MainAbout } from './styles'

const About = () => {
  const mainContext = useContext(MainContext)

  useEffect(() => mainContext.methods.changePageTitle('Sobre nós'), [])

  return (
    <_MainAbout>
      <h4>A Hoya</h4>
      <p>
        Fundada em 1941 na cidade de Tóquio, a <span>HOYA</span> é uma empresa
        global de tecnologia e soluções para área médica, conduzindo inovações
        em produtos de alta tecnologia. A <span>HOYA</span> está presente nos
        campos de cuidado da saúde e tecnologia da informação, fornecendo
        lentes oftálmicas, endoscópios, lentes intraoculares, lentes ópticas,
        assim como os principais componentes para dispositivos semicondutores,
        painéis de LCD e HDDs. Com mais de 150 sedes e subsidiarias em todo o
        mundo, a <span>HOYA</span> emprega atualmente mais de 37.000 pessoas.
        Para mais informações, visite http://www.hoya.com.
      </p>
      <p>
        *Caso tenha alguma dúvida acesse nosso <span>FAQ</span> ou envie nos um
        e-mail preenchendo o formulario em <span>Fale Conosco</span>.
      </p>
    </_MainAbout>
  )
}

export default About
