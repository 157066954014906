import React from 'react'
import { pageProtected } from '@onlypay/core-default'

import { GridTimeline } from '@onlypay/default-mod-homegrid'

const Home = () => {
  return pageProtected(<GridTimeline />, false)
}

export default Home
