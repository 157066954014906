import styled from 'styled-components'
import { colors, device } from '@onlypay/core-default'

export const _MainContact = styled.div`
	display: flex;
  flex-direction: column;

	@media ${device.tablet} {
    width: 100%;
		border-radius: 5px;
		border: 1px solid ${colors.GRAY.LVL1};
		background: ${colors.WHITE};
	}
`
