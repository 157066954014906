import styled from 'styled-components'
import { colors, device } from '@onlypay/core-default'

export const _MainQuiz = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    color: ${colors.GRAY.LVL4};
    text-align: center;
  }

  p {
    color: ${colors.GRAY.LVL4};
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    span {
      color: ${colors.PRIMARY};
    }
  }

  @media ${device.tablet} {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${colors.GRAY.LVL1};
    background: ${colors.WHITE};
    padding: 20px;
  }
`
