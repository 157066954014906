import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'
import { pageProtected } from '@onlypay/core-default'

import { MediaList } from '@onlypay/default-mod-downloadcenter'

const DownloadCenter = () => {
	const mainContext = useContext(MainContext)

	useEffect(() => mainContext.methods.changePageTitle('Novidades'), [])

	return pageProtected(<MediaList />)
}

export default DownloadCenter
