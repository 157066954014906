import React, { useContext, useEffect } from 'react'

import { MainContext } from '@onlypay/default-main-provider'
import { pageProtected } from '@onlypay/core-default'

import { ListCampaigns } from '@onlypay/default-mod-campaigns'

const CampaignsPage = ({ history }) => {
	const mainContext = useContext(MainContext)

	useEffect(() => mainContext.methods.changePageTitle('Minhas Campanhas'), [])

	return pageProtected(<ListCampaigns history={history} />)
}

export default CampaignsPage
